<template>
  <div>
    <v-row dense>
      <v-col cols="6" v-for="(campagne, index) in listOfCampagnes" :key="index">
        <campagneCard
          :amountOfUsers="campagne.amountOfUsers"
          :title="campagne.title"
          :urlItem="campagne.url"
          @setSnackbar="setSnackbar($event)"
        />
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :color="color">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import request from "../../request";
export default {
  data() {
    return {
      snackbar: false,
      text: "",
      color: "",
      listOfCampagnes: [
        {
          title: "Een team voor het leven",
          url: "/custom/send-all-customers-campaign",
          amountOfUsers: "",
        },
        {
          title: "Uitvaartverzekering",
          url: "/custom/send-funeral-campaign",
          amountOfUsers: "",
        },
        {
          title: "Tijdelijke overlijdensverzekering",
          url: "/custom/send-death-campaign",
          amountOfUsers: "",
        },
        {
          title: "Autoverzekering jonge bestuurders",
          url: "/custom/send-car-campaign",
          amountOfUsers: "",
        },
      ],
    };
  },
  created() {
    const urls = [
      "/custom/funeral-campaign-customers-count",
      "/custom/death-campaign-customers-count",
      "/custom/car-campaign-customers-count",
      "/custom/all-campaign-customers-count",
    ];
    request.getAll(urls, null, (res) => {
      if (res[0]) {
        this.listOfCampagnes[1].amountOfUsers = res[0].item;
      }
      if (res[1]) {
        this.listOfCampagnes[2].amountOfUsers = res[1].item;
      }
      if (res[2]) {
        this.listOfCampagnes[3].amountOfUsers = res[2].item;
      }
      if (res[3]) {
        this.listOfCampagnes[0].amountOfUsers = res[3].item;
      }
    });
  },
  methods: {
    setSnackbar(event) {
      this.snackbar = true;
      this.text = event.text;
      this.color = event.color;
    },
  },
};
</script>

<style>
</style>